@import "~@ctrl/ngx-emoji-mart/picker";

body {
  margin: 0;
  padding: 0;
  background: #313030 !important;
  color: rgba(255, 255, 255, .9);

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
  }
}

.ui.inverted.table thead.blue th {
  background-color: #334560;
}

.ui.inverted.blue.segment {
  background: #3f4f67;
}

.ui.inverted.segment.no-bg {
  border-radius: 0 !important;
  background: none !important;
}

.text-muted {
  margin-left: 5px;
  color: grey !important;
  font-size: 0.8em;
}

.emoji-mart {
  position: absolute;
  bottom: 25px;
  right: 0px;
}

.yellow {
  color: yellow !important;
}

.container.section {
  margin-top: 40px;
}

.description.chat-message {
  img {
    vertical-align: middle;
  }
}

.emoji {
  width: 20px;
  vertical-align: initial;
}

.ui.popup {
  .content {
    .ui.button {
      margin-bottom: 8px;
    }

    p {
      margin: 0;
    }
  }
}

.user-highlight {
  color: #28ecec !important;
}

.ui.toggle.checkbox input:checked~.box,
.ui.toggle.checkbox input:checked~label {
  color: rgba(255, 255, 255, .95) !important;
}

.ui.toggle.checkbox input:focus~.box,
.ui.toggle.checkbox input:focus:checked~label {
  color: rgba(255, 255, 255, .95) !important;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background: rgba(0, 0, 0, 0.38);
}

.ui.active.progress.top-fixed {
  z-index: 99999999;
  height: 5px;
  position: fixed !important;
  top: 0;
  border-radius: 0;
  width: 100%;
  background: rgb(146, 1, 1);

  .bar {
    position: fixed !important;
    height: 5px;
  }
}
